*{margin:0;padding:0;box-sizing:border-box}

:root {
    --grey1: 18,18,18;
    --grey2: 35,35,35;
    --grey3: 69,69,69;
    --grey4: 86,86,86;
    --grey5: 103,103,103;
    --grey6: 120,120,120;
    --grey7: 137,137,137;
    --grey8: 154,154,154;
    --grey9: 171,171,171;
    --grey10: 188,188,188;
    --grey11: 205,205,205;
    --grey12: 222,222,222;
    --grey13: 239,239,239;
    --yellow: 255,249,0;
    --red: 249,0,0;
}

html,body,#root {height: 100%}

* {
    scrollbar-width: thin;
    scrollbar-color: rgb(var(--grey3)) transparent;
}
*::-webkit-scrollbar {
    background: transparent;
    width: 0.4em;
}
*::-webkit-scrollbar-thumb {
    background: rgb(var(--grey3));
}

body {
    background: rgb(var(--grey1));
    color: rgb(var(--grey13));
    margin: 0;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    user-select: none;
}

a, a:link, a:visited {
    color: rgb(var(--grey13));
    text-decoration: none;
}
a:hover {
    color: rgb(var(--grey9));
    text-decoration: underline;
}

.btn,
.input,
.select select {
    background-color: rgb(var(--grey2));
    color: rgb(var(--grey12));
    border: 1px solid rgb(var(--grey3));
    padding: 0.5rem 0.6rem;
    display: block;
    border-radius: 0;
    outline: none;
    font-size: 14px;
}

.input {
    width: 100%;
}

.select select {
    appearance: none;
    cursor: pointer;
    width: 100%;
    padding-right: 2em;
}
.select {
    position: relative;
    background-color: rgb(var(--grey1));
}
.select:after {
    display: block;
    content: '\25BE';
    transform: scale(1.5);
    position: absolute;
    right: 0.75rem;
    top: 0.5rem;
    pointer-events: none;
}

form {
    width: 30%;
    margin: 0 auto;
}

.form-group {
    padding: 0.5em 1em;
}

.form-group * {
    margin: 0.5em 0;
}

.form-group .btn {
    display: inline;
    margin: 0.25em 0.5em 0.25em 0;
}
.form-group.errored input,
.form-group.errored select,
.form-group.errored textarea {
    border: 1px solid rgba(var(--red), 0.8);
}

.no-show {display: none}
.bold {font-weight: bold}
.italic {font-style: italic}
.text-lg {font-size: 1.2em}
.flex-1 {flex: 1}

.underline {
    text-decoration: underline;
    text-decoration-color: rgb(var(--grey8));
}

.filter-container {
    width: 60%;
    margin: 0.25em 0 0.75em 0;
    display: flex;
    align-items: center;
    line-height: 1.1em;
    background-color: rgb(var(--grey2));
    color: rgb(var(--grey12));
    border: 1px solid rgb(var(--grey3));
}

.filter-clear,
.filter-number,
.filter-input {
    background: transparent;
    border-width: 0;
    padding: 0.5rem 0.6rem;
}

.filter-clear {
    cursor: pointer;
    border-width: 0 1px;
}

.container {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.header {
    padding: 1em;
}
